import React, {Component} from 'react';
import {Button, Card, CardBody, CardSubtitle, CardText, CardTitle} from "reactstrap";
import moment from "moment";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ArticleDeleteModal from "./ArticleDeleteModal";

import './ArticleCard.scss'
import {highlightArticle} from "../../../data/entities/Article";
import ArticleOverviewModal from "./ArticleOverviewModal";
import ArticleUnpublishModal from "./ArticleUnpublishModal";
import ArticlePublishModal from "./ArticlePublishModal";
import ArticleDuplicateModal from "./ArticleDuplicateModal";
import {fetchUser} from "../../../data/entities/User";
import ArticleTranslateModal from "./ArticleTranslateModal";
class ArticleCard extends Component
{
    state = {
        showArticleOverviewModal: false,
        showArticleDeleteModal: false,
        article: this.props.article,
        showUnpublishModal: false,
        showPublishModal: false,
        showArticleTranslateModal: false,
        showDuplicateModal: false
    };

    _highlight = (highlight = true) => {
        this.props.highlightArticle(this.state.article.id, highlight)
            .then(res => {
                this.setState({
                    article: res.payload.data
                })
            })
    }

    render()
    {
        const  userrole= this.props.user.profile;
        const article = this.state.article

        const { categories } = article
        var style = article.cover && article.cover.file_thumbnail_wp ? { backgroundImage: `url(${article.cover.file_thumbnail_wp})` } : {}
        // check image inside RSS DATA
        var image=""
        if(typeof article.rss_data !== 'undefined'  && article.rss_data && article.rss_data.length > 0){
            if(typeof article.rss_data[0]['image'] !== 'undefined' && article.rss_data[0]['image'] && article.rss_data[0]['image'].length > 0 && typeof article.rss_data[0]['imageDeleted'] === 'undefined'){
                if(Array.isArray(article.rss_data[0]['image'])){
                    image=article.rss_data[0]['image'][0]
                }else{
                    image=article.rss_data[0]['image']
                }
                if(Object.keys(style).length === 0) style={ backgroundImage: `url(${image})` }

            }
        }

        const external = !!article.source_platform_name
        const published = article.publication_status && moment(article.publication_start) <= moment() && (!article.publication_end || moment(article.publication_end) > moment())
        const highlighted = article.highlight_status && moment(article.highlight_start) <= moment() && (!article.highlight_end || moment(article.highlight_end) > moment())

        const is_contributor = userrole.privileges.includes("article:mine") ? true : false;
        const is_manager_article = userrole.privileges.includes("article:manage") ? true : false;
        const admin = this.props.user.profile.roles.indexOf('ROLE_ADMIN') !== -1 ? true : false;
        return (

            <>
                {  is_contributor || admin || is_manager_article ?
            <div className={"col-md-6 col-lg-4 col-xl-3 mb-3 article-card"} key={'article-' + article.id}>
                <Card>
                    <div className="article-card-thumbnail" style={style}>
                        {(!article.cover && !article.imported)
                        || (typeof article.cover === 'undefined' &&  typeof article.cover.file_thumbnail === 'undefined' && !article.imported)
                        || (article.imported && !image && typeof article.rss_data[0]['imageDeleted'] === 'undefined')
                            ? <i className="fas fa-image"/> : null}
                        <div className="article-card-actions">
                            <div>
                                {published && highlighted ? (
                                    <div className="btn btn-warning"
                                         onClick={() => this._highlight(false)}>
                                        <i className="fa fa-star-half"/>
                                        <span className="btn-tooltip top">Ne pas mettre en avant</span>
                                    </div>
                                ) : null}
                                {published && !highlighted ? (
                                    <div className="btn btn-warning"
                                         onClick={this._highlight}>
                                        <i className="fa fa-star"/>
                                        <span className="btn-tooltip top">Mettre en avant</span>
                                    </div>
                                ) : null}
                                {!published && !article.scoop && !is_contributor ?
                                    <div className="btn btn-success"
                                         onClick={this.togglePublishModal}>
                                        <i className="fa fa-check"/>
                                        <span className="btn-tooltip top">Publier</span>
                                    </div>
                                    : null}
                                {published && !is_contributor ?
                                    <div className="btn btn-success"
                                         onClick={this.toggleUnpublishModal}>
                                        <i className="fa fa-times"/>
                                        <span className="btn-tooltip top">Dépublier</span>
                                    </div>
                                    : null}
                                <div className="btn btn-info"
                                     onClick={this.toggleDuplicateModal}>
                                    <i className="fa fa-copy"/>
                                    <span className="btn-tooltip top">Dupliquer</span>
                                </div>
                            </div>

                            <div>
                                <div className="btn btn-primary"
                                     onClick={() => this.setState({ showArticleOverviewModal: true })}>
                                    <i className="fa fa-eye"/>
                                    <span className="btn-tooltip">Prévisualiser</span>
                                </div>
                                <Link to={"/editor/article/" + article.id} className="btn btn-secondary">
                                    <i className="fa fa-edit"/>
                                    <span className="btn-tooltip">Modifier</span>
                                </Link>
                                <div className="btn btn-danger"
                                     onClick={() => this.setState({ showArticleDeleteModal: true })}>
                                    <i className="fa fa-trash"/>
                                    <span className="btn-tooltip">Suppression</span>
                                </div>

                                {
                                     <div className="btn btn-light"
                                                         onClick={() => this.setState({ showArticleTranslateModal: true })}>
                                        <img src={'../themes/icon/deepl_logo_icon.svg'} height={30} width={20} />
                                        <span className="btn-tooltip">Traduction</span>
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                    <CardBody>
                        <div className="article-badges">
                            {external ? (
                                <div className="article-badge badge-external">
                                    <i className="fa fa-globe"/>
                                </div>
                            ) : null}
                            {published ? (
                                <div className="article-badge badge-published">
                                    <i className="fa fa-check"/>
                                </div>
                            ) : null}
                            {highlighted ? (
                                <div className="article-badge badge-highlighted">
                                    <i className="fa fa-star"/>
                                </div>
                            ) : null}
                            {article.scoop ? (
                                <div className="article-badge badge-scoop">
                                    <i className="fa fa-exclamation"/>
                                </div>
                            ) : null}
                        </div>
                        <div className={'words-count'}>
                            <i>
                                { article.content_length } mot{article.content_length > 1 && 's'}
                            </i>
                        </div>
                        <div style={{ minHeight: 78 }}>
                            <CardTitle>
                                <strong>{article.title}</strong>
                            </CardTitle>
                        </div>
                        <CardText>
                            <i className="fa fa-user text-muted mr-2"/>
                            {article.author_name ? article.author_name : "Anonyme"}
                        </CardText>
                        <div className={"article-card-categories"}>
                            {categories.length ?
                                categories.map((category, i) => <div className={"badge badge-" + (i == 0 ? 'primary' : 'secondary')}>{category.name}</div>)
                                : <div className={"text-muted"}>Aucune catégorie</div>
                            }
                        </div>
                        {this.state.showArticleDeleteModal &&
                        <ArticleDeleteModal
                            data={article}
                            onClose={deleted => {
                                this.setState({ showArticleDeleteModal: false });
                                this.props.onDelete()
                            }}
                        />}


                        {this.state.showArticleTranslateModal &&
                            <ArticleTranslateModal
                                data={article}
                                link={true}
                                onClose={() => this.setState({ showArticleTranslateModal: false })}
                            />}


                        {this.state.showArticleOverviewModal &&
                        <ArticleOverviewModal
                            data={article}
                            link={true}
                            onClose={() => this.setState({ showArticleOverviewModal: false })}/>
                        }
                        {this.state.showUnpublishModal &&
                        <ArticleUnpublishModal
                            data={article}
                            onClose={() => this.toggleUnpublishModal()}
                        />
                        }
                        {this.state.showPublishModal &&
                        <ArticlePublishModal
                            data={article}
                            onClose={() => this.togglePublishModal()}
                        />
                        }
                        {this.state.showDuplicateModal &&
                        <ArticleDuplicateModal
                            data={article}
                            onClose={() => this.toggleDuplicateModal()}
                            duplicated={() => this.duplicated()}
                        />
                        }
                    </CardBody>
                </Card>
            </div>
                    : null}
            </>

        );
    }

    toggleUnpublishModal = () => {
        this.setState({ showUnpublishModal: !this.state.showUnpublishModal });
    };

    togglePublishModal = () => {
        this.setState({ showPublishModal: !this.state.showPublishModal });
    };

    toggleDuplicateModal = () => {
        this.setState({ showDuplicateModal: !this.state.showDuplicateModal });
    };

    duplicated = () => {
        this.toggleDuplicateModal();
        this.props.updated();
    };
}

export default connect(({ user_entity, role_entity, user }) => ({ user_entity, role_entity, user }), { highlightArticle, fetchUser })(ArticleCard);
